import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import configureStore, { history } from "./redux/store";
import * as Sentry from '@sentry/browser';
import 'airbnb-js-shims';

const store = configureStore();

Sentry.init({ dsn: "https://0a15e1c931144789992c151d47a26603@sentry.io/1727926" });

ReactDOM.render(<Provider store={store}>
  <ConnectedRouter history={history}>
    <App store={store} />
  </ConnectedRouter>
</Provider>,
  document.getElementById('root'));

serviceWorker.unregister();
