import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SiteHeading from '../SiteHeading';
import SiteContent from '../SiteContent';
import ContactCard from '../ContactCard';
import Language from '../Language';
import SiteTitle from '../SiteTitle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import '../scss/Desktop.scss';
import '../scss/SiteContent.scss';

export default (props) => {

  const { contactHeading, data, homeBackground, lang } = props;
  const { fields } = data.items[0];
  const copyright = documentToReactComponents(fields.copyright)
  let contactCardContent = "", legalNoticeContent = "", languageContent = "";

  if (props.page === undefined) {
    contactCardContent = <ContactCard contactHeading={contactHeading} />;
    legalNoticeContent = <div className="body-legal-notice"> <a href="/legal-notice">Legal notice</a></div>;
    languageContent = <Language lang={lang} />;
  }

  return (<div className="Desktop home pb-4" style={homeBackground}>
    <Container>
      <Row  >
        <Col className="">
          {languageContent}
        </Col>
        <SiteTitle siteName={data.items[0].fields.siteName} />
      </Row>
      <Row>

        <Col className="mt-6 py-5"></Col>
      </Row>
      <Row className="text-left">
        <SiteHeading content={data} />
      </Row>

    </Container>

    <Container className="SiteContent">
      <div className="scrim mb-4">
        <SiteContent content={data} />
        {contactCardContent}
        <div className="body-text-copyright"> {copyright}</div>
        {legalNoticeContent}
      </div>
    </Container>

  </div>)
}
