import React from 'react';
import './scss/SiteTitle.scss';
import logo from '../assets/images/logo.png';
export default () => {
  return (
    <div className="SiteTitle">
      <div className="logo-container" > <h1> <img src={logo} alt="Helectron Logo" /> </h1></div>
    </div>
  );
};
